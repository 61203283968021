/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'


import { message } from 'antd'
import axios from 'axios'
import { AiTwotoneDelete } from 'react-icons/ai'
import { BsFillPencilFill } from 'react-icons/bs'
import { useAuthcontexts } from '../../../../contexts/Authcontexts'
import { useFetchCashBook } from '../../../../contexts/FetchCashBook'
import { calculateTotal } from '../../../../components/GlobalFunctions'
import { useFetchCustomerContext } from '../../../../contexts/FetchCustomersContext'
import { URL } from '../../../../Constants'




const initialState = { date: "", description: "CASH", debit: 0, credit: 0 }
const initialvalue = { fullName: "" }
export default function Add() {

  const [state, setState] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getAcName, setgetAcName] = useState(initialvalue);
  const nameInputRef = useRef(null);
  // fetch Data of user

  const { user } = useAuthcontexts();

  // fetch Data of Cutomers

  const { customers } = useFetchCustomerContext();

  const sortedCustomers = [...customers].sort((a, b) => a.fullName.localeCompare(b.fullName));

  // fetch Data of CashBook

  const { getCashBook, setGetCashBook } = useFetchCashBook();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleName = e => {
    setgetAcName({ ...getAcName, [e.target.name]: e.target.value })
  }
  const handleDateChange = e => {
    setSelectedDate(e.target.value)
  }
  // useEffect(() => {
  //   const currentDate = new Date();
  //   const formattedDate = currentDate.toISOString().slice(0, 10);
  //   currentDate.setMonth(currentDate.getMonth() - 6);
  //   const formattedDatebefore = currentDate.toISOString().slice(0, 10)
  //   setSelectedDate(formattedDate);
  //   console.log('formattedDatebefore', formattedDatebefore)
  // }, []);


  let lastVocherNo = 0
  if (!getCashBook.length) {
    lastVocherNo = 0
  }
  else {
    const maxVoucherNo = Math.max(...getCashBook.map((entry) => entry.vocherNo));
    lastVocherNo = maxVoucherNo;
  }


  const filterCashBook = getCashBook.filter((cashBook) => cashBook.date === selectedDate);

  const sortedCashBook = [...filterCashBook].sort((a, b) => a.vocherNo - (b.vocherNo));

  // Add New Data in CashBook

  const handleSubmit = async (e) => {
    e.preventDefault()

    let { description, debit, credit } = state
    let { fullName } = getAcName
    debit = Number(debit);
    credit = Number(credit);
    const vocherNo = lastVocherNo + 1;



    // Name Validation

    if (!fullName || fullName === "") {
      return message.error("Please select any account name ")
    }
    if (!selectedDate) {
      return message.error("Please select any Date")
    }

    if (credit === 0 && debit === 0) {

      return message.error("Both Credit / Debit Are Zero, Please Put The Amount In One Of Them")
    }
    if (credit > 0 && debit > 0) {

      return message.error("You Are Not Adding Both Credit / Debit At Same Time ")
    }
    if (credit < 0 || debit < 0) {

      return message.error("Negative Value Not Allowed in Credit / Debit")
    }
    if (!user) {
      return message.error("Please Login First")
    }

    const validVocherNo = getCashBook.find((cashbook) => cashbook.vocherNo === vocherNo);
    if (validVocherNo) {
      return message.error("You had already Save Transection on this Vocher No")
    }
    setIsLoading(true);
    const data = {
      vocherNo,
      date: selectedDate,
      description,
      debit,
      credit,
      vocherType: 'Cv',
      id: Math.random().toString(36).slice(2),
      dateCreated: new Date().toISOString().slice(0, 10),
      shopId: user.shopId,
      createdByUserName: user.fullName,
      createdByUserEmail: user.email,
      createdByUserId: user.id,
      customerID: "",
    }

    // Find the user object with the selected username
    const selectedUser = customers.find(user => user.fullName === fullName);

    data.customerID = selectedUser.id;

    axios.post(`${URL}/cashBook/add`, data)
      .then((res) => {
        setGetCashBook([...getCashBook, data])
        setState(initialState)
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
        message.success("Transection Added Successfully")
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status) {
          message.error(error.response.data.error)
          setIsLoading(false);
        }
        else {
          message.error(error.message)
          setIsLoading(false);
        }
      })
  }

  // *******End*******//


  // UpDate The Data Entity From CashBook 

  const handleUpdate = (entity) => {
    setState(entity)
    setIsProcessing(true)
    const Acname = customers.find((abc) => abc.id === entity.customerID)
    setgetAcName(Acname)
  }
  const afterUpdate = async (e) => {
    let { fullName } = getAcName
    const updateData = {
      ...state
    }

    updateData.credit = Number(updateData.credit)
    updateData.debit = Number(updateData.debit)
    updateData.date = new Date(selectedDate).toISOString().slice(0, 10);

    const selectedUser = customers.find(user => user.fullName === fullName);
    updateData.customerID = selectedUser.id

    const updateCashBook = getCashBook.map(oldCashBook => {
      if (oldCashBook.id === updateData.id)
        return updateData
      return oldCashBook
    })
    setGetCashBook(updateCashBook)
    console.log('updateData', updateData)
    axios.post(`${URL}/cashBook/update/${updateData.id}`, updateData)
      .then((res) => {
        message.success("CashBook updated successfully")
        setIsProcessing(false)
        setState(initialState)
      })
      .catch((error) => {
        if (error.response.status) {
          message.error(error.response.data.error)
          setIsProcessing(false)
        }
        else {
          message.error(error.message)
          setIsProcessing(false)
        }
      })


  }
  // *******End*******//

  // Deleing The Data Entity from CashBook

  const handleDelete = async (user) => {
    console.log('user', user)
    axios.post(`${URL}/cashBook/delete/${user.id}`)
      .then((res) => {
        let cashBookAfterDelete = getCashBook.filter(doc => doc.id !== user.id)
        setGetCashBook(cashBookAfterDelete)
        message.success("Entity Deleted successfully")
      })
      .catch((error) => {
        if (error.response.status)
          message.error(error.response.data.error)
        else
          message.error(error.message)
      })

  }

  // *******End*******// 

  const sumCredit = calculateTotal(getCashBook, "credit");
  const sumDebit = calculateTotal(getCashBook, "debit");

  let openingBalance = 0;
  for (const key in customers) {
    if (customers.hasOwnProperty(key)) {
      const TotalOpeningBalance = customers[key].openingBalance;
      openingBalance += TotalOpeningBalance;
    }
  }

  const rowsPerPage = 30;
  const lastIndex = currentPage * rowsPerPage;
  const firstIndex = lastIndex - rowsPerPage;
  const data = sortedCashBook.slice(firstIndex, lastIndex);
  const npages = Math.ceil(sortedCashBook.length / rowsPerPage);
  const number = [...Array(npages + 1).keys()].slice(1)

  const handleChangePage = (id) => {
    setCurrentPage(id)
  }
  const handlePre = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const handleNext = () => {
    if (currentPage !== npages) {
      setCurrentPage(currentPage + 1)
    }
  }






  const tableRows = data.map((entry, i) => {
    const account = customers.find((abcd) => abcd.id === entry.customerID);
    return (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>{entry.date}</td>
        <td>{entry.vocherNo}</td>
        <td style={{ textTransform: 'capitalize' }}>{account ? account.fullName : 'N/A'}</td>
        <td>{entry.description}</td>
        <td className='text-center'>{entry.debit}</td>
        <td className='text-center'>{entry.credit}</td>
        <td className='text-center'>
          <button className='btn btn-info p-1 py-0 rounded-2 me-0 me-md-1' onClick={() => { handleUpdate(entry) }}> <span className='text-white my-1 d-flex align-items-center'><BsFillPencilFill size={16} /></span> </button>
          <button className='btn btn-danger p-1 py-0 rounded-2' onClick={() => { handleDelete(entry) }}> <span className='text-white my-1 d-flex align-items-center'><AiTwotoneDelete size={16} /></span> </button>
        </td>
      </tr>
    );
  });
  return (
    <>

      <div className="container-fluid mb-1">
        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <div style={{ borderRadius: '1rem' }}>
              <div className="p-0 bg-white">

                <div className="mb-2" >
                  <form onSubmit={handleSubmit}>
                    <div className="row mx-2 pt-2 d-flex justify-content-between">
                      <div className="col-6 mb-2 col-md-2 ps-1">
                        <label htmlFor="" style={{ color: "#156829" }} className='py-1'>Vocher #</label>
                        <input type="number" name='vocherNo' className='form-control form-control-sm ' value={lastVocherNo + 1} onChange={handleChange} />
                      </div>
                      <div className="col-6 mb-2 col-md-3 ps-1">
                        <label htmlFor="" style={{ color: "#156829" }} className='py-1'>Date</label>
                        <input type="date" name='date' className='form-control form-control-sm ' value={selectedDate} onChange={handleDateChange} />
                      </div>
                    </div>
                    <div className="row mx-2 mb-md-2">
                      <div className="col-12 mb-2 col-md-3 ps-1 pe-0">
                        <label htmlFor="" style={{ color: "#156829" }}>Account Name</label>
                        {/* <input type="text" name='fullName' className='form-control form-control-sm ' onChange={handleChange} /> */}
                        <select ref={nameInputRef} className="form-control form-control-sm" name='fullName' value={getAcName.fullName} onChange={handleName}>
                          <option value="">Select</option>
                          {
                            sortedCustomers.map((acName) => {
                              return (
                                <option key={acName.id} value={acName.fullName}>{acName.fullName + " " + acName.city}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className="col-12 mb-2 col-md-3 pe-0 ps-1">
                        <label htmlFor="" style={{ color: "#156829" }}>Description</label>
                        <input type="text" name='description' className='form-control form-control-sm ' value={state.description} onChange={handleChange} />
                      </div>
                      <div className="col-6 mb-2 col-md-3 pe-0 ps-1">
                        <label htmlFor="" style={{ color: "#156829" }}>Debit</label>
                        <input type="number" name='debit' className='form-control form-control-sm ' value={state.debit} onChange={handleChange} />
                      </div>
                      <div className="col-6 mb-2 col-md-3 pe-0 pe-md-2 ps-1">
                        <label htmlFor="" style={{ color: "#156829" }}>Credit</label>
                        <input type="number" name='credit' className='form-control form-control-sm ' value={state.credit} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mx-2">
                      <div className="col text-end px-0 px-md-2">
                        {
                          isProcessing ?
                            <button className='btn btn-white  mb-3' type='button' onClick={afterUpdate} style={{ background: "#c1e6c3" }}><span style={{ color: '#237335' }}>update</span> </button>
                            :
                            !isLoading ?
                              <div className="col text-end">
                                <button className='btn btn-white fw-bold mb-3' type='submit' style={{ background: "#c1e6c3", width: '100px' }}><span style={{ color: '#237335' }}>Add</span> </button>
                              </div>
                              : <div className="col text-end">
                                <button className="btn btn-success border-success mb-3" type="button" disabled>
                                  <span className="spinner-border text-success spinner-border-sm" role="status" aria-hidden="true"></span>
                                  Loading...
                                </button>

                              </div>

                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div className="table-responsive rounded">
              <table className="table table-sm">
                <thead>
                  <tr className='' style={{ background: "#c1e6c3", color: "#005a16" }}>
                    <th >Sr.No</th>
                    <th >Date</th>
                    <th >V.No</th>
                    <th >Account Name</th>
                    <th >Description</th>
                    <th className='text-center'>Debit</th>
                    <th className='text-center'>Credit</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='text-center'>
                    <th colSpan={6}>CASH IN HAND</th>
                    <th colSpan={2} className='text-danger'>{sumCredit - sumDebit + openingBalance}</th>
                  </tr>
                  {
                    tableRows
                  }
                </tbody>
              </table>
            </div>
          </div>
          {
            data.length ?
              <nav>
                <ul className="pagination pagination-sm justify-content-end">
                  <li className="page-item">
                    <button className="page-link" aria-label="Previous" onClick={handlePre}>
                      Prev
                    </button>
                  </li>
                  {
                    number.map((n, i) => (
                      <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handleChangePage(n)}>{n}</button>
                      </li>
                    ))
                  }
                  <li className="page-item">
                    <button className="page-link" aria-label="Next" onClick={handleNext}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              : ""
          }
        </div>
      </div>
    </>
  )
}
