/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useFetchCashBook } from '../../../../contexts/FetchCashBook';
import { useFetchCustomerContext } from '../../../../contexts/FetchCustomersContext';
import { BiSearch } from 'react-icons/bi';


const initialState = { show: "", date: "" }
export default function FilterCashBook() {

    const [state, setState] = useState(initialState);
    const [currentPage, setCurrentPage] = useState(1);
    const { getCashBook } = useFetchCashBook();
    const { customers } = useFetchCustomerContext();


    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    //**** Get Day start Cash In Hand****//

    const filteredByDate = getCashBook.filter((entity) => entity.date < state.date);
    const filtered = [...filteredByDate].sort((a, b) => a.vocherNo - (b.vocherNo));

    // For Total Of Credit

    let sumfilteredCredit = 0;
    for (const key in filtered) {
        if (filtered.hasOwnProperty(key)) {
            const creditValue = filtered[key].credit;
            sumfilteredCredit += creditValue;
        }
    }

    // For Total Of Debit

    let sumfilteredDebit = 0;
    for (const key in filtered) {
        if (filtered.hasOwnProperty(key)) {
            const debitValue = filtered[key].debit;
            sumfilteredDebit += debitValue;
        }
    }

    let openingBalance = 0;
    for (const key in customers) {
        if (customers.hasOwnProperty(key)) {
            const TotalOpeningBalance = customers[key].openingBalance;
            openingBalance += TotalOpeningBalance;
        }
    }

    let cashInHand = sumfilteredCredit - sumfilteredDebit + openingBalance;

    // *****End*****//

    //**** Get Day End Cash In Hand****//

    const selectedEntities = getCashBook.filter(entity => entity.date === state.date);
    const sortedByVocherNo = [...selectedEntities].sort((a, b) => a.vocherNo - (b.vocherNo));

    //  For Total Of Credit

    let sumCredit = 0;
    for (const key in selectedEntities) {
        if (selectedEntities.hasOwnProperty(key)) {
            const mark = selectedEntities[key].credit;
            sumCredit += mark;
        }
    }

    // For Total Of Debit

    let sumDebit = 0;
    for (const key in selectedEntities) {
        if (selectedEntities.hasOwnProperty(key)) {
            const mark = selectedEntities[key].debit;
            sumDebit += mark;
        }
    }

    //****End****//

    const rowsPerPage = 30;
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage;
    const data = sortedByVocherNo.slice(firstIndex, lastIndex);
    const npages = Math.ceil(sortedByVocherNo.length / rowsPerPage);
    const number = [...Array(npages + 1).keys()].slice(1)

    const handleChangePage = (id) => {
        setCurrentPage(id)
    }
    const handlePre = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const handleNext = () => {
        if (currentPage !== npages) {
            setCurrentPage(currentPage + 1)
        }
    }


    let { show } = state;
    show = Number(show)

    const tableRows = data.map((entry, index) => {
        const account = customers.find((abcd) => abcd.id === entry.customerID);
        return (
            <tr key={index} className=''>
                <td>{index + 1}</td>
                <td>{entry.date}</td>
                <td>{entry.vocherNo}</td>
                <td style={{ textTransform: 'capitalize' }}>{account ? account.fullName : 'N/A'}</td>
                <td>{entry.description}</td>
                <td className='text-center'>{entry.debit}</td>
                <td className='text-center'>{entry.credit}</td>
            </tr>
        );
    });


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className='mb-3 d-flex flex-row justify-content-between'>
                            <div className='d-flex flex-row me-1'>
                                <span className=' d-none d-sm-inline fw-bold me-2 mt-1'>Show </span>
                                <select className="form-control-sm ms-lg-0" name='show' onChange={handleChange}>
                                    <option>01</option>
                                    <option>05</option>
                                    <option>10</option>
                                    <option>15</option>
                                    <option>20</option>
                                </select>
                                <span className='ms-2 mt-1 d-none d-sm-inline  fw-bold'>Rows</span>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="input-group mb-3">
                            <span className="input-group-text bg-white" id="basic-addon1"><BiSearch /></span>
                            <input type="date" className="form-control rounded-end" placeholder="Search" name='date' aria-describedby="basic-addon1" onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div>
                <p className='text-end fw-bold px-0 px-md-3'>
                    CASH IN HAND: <span className='text-danger'>{cashInHand}</span>
                </p>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-3 px-0">
                            <div className="table-responsive rounded">
                                <table className="table table-sm">
                                    <thead>
                                        <tr className='' style={{ background: "#c1e6c3", color: "#005a16" }}>
                                            <th>Sr.No</th>
                                            <th>Date</th>
                                            <th>CV.No#</th>
                                            <th>AC Name</th>
                                            <th>Description</th>
                                            <th className='text-center'>Debit</th>
                                            <th className='text-center'>Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableRows
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className='text-center'>
                                            <th className='text-end' colSpan={5}>Total</th>
                                            <th>{sumDebit}</th>
                                            <th>{sumCredit}</th>
                                        </tr>
                                        <tr className='text-center'>
                                            <th className='text-end' colSpan={5}>CASH IN HAND</th>
                                            <th className='text-danger' colSpan={2}>{cashInHand + sumCredit - sumDebit}</th>

                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        {
                            data.length ?
                                <nav>
                                    <ul className="pagination pagination-sm justify-content-end">
                                        <li className="page-item">
                                            <button className="page-link" aria-label="Previous" onClick={handlePre}>
                                                Prev
                                            </button>
                                        </li>
                                        {
                                            number.map((n, i) => (
                                                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                    <button className="page-link" onClick={() => handleChangePage(n)}>{n}</button>
                                                </li>
                                            ))
                                        }
                                        <li className="page-item">
                                            <button className="page-link" aria-label="Next" onClick={handleNext}>
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                                : ""
                        }
                    </div>
                </div>
            </div>

        </>



    )
}
