import React from 'react'

export const calculateBalances = (customers, getCashBook) => {
    const calculatedBalances = customers.map(customer => {
        const customerCashbook = getCashBook.filter(entry => entry.customerID === customer.id);
        const totalCredit = customerCashbook.reduce((total, entry) => total + entry.credit, 0);
        const totalDebit = customerCashbook.reduce((total, entry) => total + entry.debit, 0);
        const balance = totalCredit - totalDebit + customer.openingBalance;

        return {
            customerId: customer.id,
            customerName: customer.fullName,
            customerCity: customer.city,
            balance: balance,
            customerType: customer.typeOfAccount,
            customerOpeningBalance: customer.openingBalance
        };
    });

    return calculatedBalances;
};


export const calculateTotal = (filterObject, valueType) => {
    let calculateTotal = 0;

    for (const key in filterObject) {
        if (filterObject.hasOwnProperty(key)) {
            let value;
            if (valueType === 'credit') {
                value = filterObject[key].credit;
            } else if (valueType === 'debit') {
                value = filterObject[key].debit;
            } else {

                continue;
            }
            calculateTotal += value;
        }
    }

    return calculateTotal;
};

export const AccountSection = ({ title, data }) => {
    const calculateTotalBalance = (accounts, isDebit) => {
        return accounts.reduce((total, entity) => {
            if (isDebit) {
                return entity.balance > 0 ? total : total - entity.balance;
            } else {
                return entity.balance > 0 ? total + entity.balance : total;
            }
        }, 0);
    };

    const hasNonZeroBalance = (accounts) => {
        return accounts.some(entity => entity.balance !== 0);
    };

    return (
        <div className="col-12">
            <h5 className='ms-2' style={{ color: "#0c6321" }}>{title}</h5>
            <div className="table-responsive rounded">
                <table className="table table-hover table-sm">
                    <thead>
                        <tr style={{ background: "#c1e6c3", color: "#005a16" }}>
                            <th>Account Name</th>
                            <th>Debit</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.filter(typeData => hasNonZeroBalance(typeData.accounts)).map((typeData, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td colSpan="3" style={{ fontWeight: 'bold' }}>{typeData.type} Accounts</td>
                                </tr>
                                {typeData.accounts.map((entity, i) => (
                                    <tr key={i}>
                                        <td style={{ textTransform: 'capitalize' }}>{entity.customerName + " " + entity.customerCity}</td>
                                        {entity.balance > 0 ? <td></td> : <td>{-entity.balance}</td>}
                                        {entity.balance > 0 ? <td>{entity.balance}</td> : <td></td>}
                                    </tr>
                                ))}
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Total Balance:</td>
                                    <td style={{ fontWeight: 'bold', color: "#e63946" }}>
                                        {calculateTotalBalance(typeData.accounts, true)}
                                    </td>
                                    <td style={{ fontWeight: 'bold', color: "#2a9134" }}>
                                        {calculateTotalBalance(typeData.accounts, false)}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
